.d-flex {
  &.items-spacing {
    & > * {
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}