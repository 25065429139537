.itp-resource-meta {
  display: table;

  &__row {
    display: table-row;

    > * {
      display: table-cell;
      padding-left: 0.25rem;
      padding-right: 0.25rem
    }
  }
}