
.badge {
  /**
   * badge styles borrowed from https://mdbootstrap.com/docs/standard/components/badges/
  */
  // display: inline-block;
  // padding: .35em .65em;
  font-size: .9rem;
  // font-weight: 700;
  // line-height: 1;
  // color: #fff;
  // text-align: center;
  // white-space: nowrap;
  // vertical-align: baseline;
  // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  // border-radius: .25rem;

  &.badge-sm {
    font-size: .8rem;
  }

  &.badge-icon {
    font-size: 1rem;
    padding: .15em .25em;
  }

  &.badge-pill.badge-dot {
    padding-left: 0.3em;
    padding-right: 0.7em;
    svg {
      vertical-align: bottom;
    }
  }
}

.badge-alt {
  &.badge-default {
    color: #fff !important;
    background-color: #2bbbad !important;
  }

  &.badge-primary {
    color: #fff !important;
    background-color: #4285f4 !important;
  }

  &.badge-secondary {
    color: #fff !important;
    background-color: #a6c !important;
  }

  &.badge-success {
    color: #fff !important;
    background-color: #00c851 !important;
  }

  &.badge-danger {
    color: #fff !important;
    background-color: #ff3547 !important;
  }

  &.badge-warning {
    color: #fff !important;
    background-color: #fb3 !important;
  }

  &.badge-info {
    color: #fff !important;
    background-color: #33b5e5 !important;
  }

  &.badge-light {
    color: #000 !important;
    background-color: #e0e0e0 !important;
  }

  &.badge-dark {
    color: #fff !important;
    background-color: #212121 !important;
  }

  &.badge-mjml {
    color: #fff !important;
    background-color: #EC5B41 !important;
  }
}