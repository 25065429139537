// @import '~bootstrap/scss/variables';

.itp-labeled-list-group-item {
  label {
    font-weight: bold;
  }
}


.list-group-alt-1 {
  & > .itp-labeled-list-group-item {
    border: none !important;
    padding: 0;

    label {
      font-weight: normal;
      color: #6c757d !important;
      margin: 0;
    }

    &__label {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &__content {
      font-weight: bold;
    }
  }

  &.condensed > .itp-labeled-list-group-item {
    &__label {
      padding: 0;
    }
  }
}