/*
IMPORTANT:
In order to preserve the uniform grid appearance, all cell styles need to have padding, margin and border sizes.
No built-in (selected, editable, highlight, flashing, invalid, loading, :focus) or user-specified CSS
classes should alter those!
*/

.slick-header.ui-state-default,
.slick-headerrow.ui-state-default,
.slick-footerrow.ui-state-default  {
  width: 100%;
  overflow: hidden;
}

.slick-header-columns,
.slick-headerrow-columns,
.slick-footerrow-columns {
  position: relative;
  white-space: nowrap;
  cursor: default;
  overflow: hidden;
}

.slick-header-sortable { cursor:pointer; }

.slick-header-column.ui-state-default {
  position: relative;
  display: inline-block;
  box-sizing: content-box !important;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  height: 16px;
  line-height: 16px;
  margin: 0;
  padding: 4px;
  border-right: 1px solid silver;
  border-left: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  float: left;
}

.slick-headerrow-column.ui-state-default,
.slick-footerrow-column.ui-state-default {
  padding: 1px 4px;

  input.column-filter {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
  }
}

.slick-header-column-sorted {
  font-style: italic;
}

.slick-sort-indicator {
  display: inline-block;
  width: 8px;
  height: 5px;
  margin-right: 4px;
  margin-top: 6px;
  float: left; }
.slick-sort-indicator-desc {
  background: url(./images/sort-desc-blue.gif);
}
.slick-sort-indicator-asc {
  background: url(./images/sort-asc-blue.gif);
}

.slick-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  display: block;
  cursor: col-resize;
  width: 4px;
  right: 0px;
  top: 0;
  height: 100%;
}

.slick-sortable-placeholder {
  background: silver;
}

.grid-canvas {
  position: relative;
  outline: 0;
}

.slick-row.ui-widget-content,
.slick-row.ui-state-active {
  position: absolute;
  border: 0px;
  width: 100%;
}

.slick-cell,
.slick-headerrow-column,
.slick-footerrow-column {
  position: absolute;
  border: 1px solid transparent;
  border-right: 1px dotted silver;
  border-bottom-color: silver;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  vertical-align: middle;
  z-index: 1;
  padding: 1px 2px 2px 1px;
  margin: 0;
  white-space: nowrap;
  cursor: default;
}
.slick-cell {
  height: 100%;
  border-bottom: 0;
}
.slick-headerrow-column{
  // height: 100%;
  border-bottom: 0;
}
.slick-footerrow-column {}

.slick-group {
}

.slick-group-toggle {
  display: inline-block;
}

.slick-cell.highlighted {
  background: lightskyblue;
  background: rgba(0, 0, 255, 0.2);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.slick-cell.flashing {
  border: 1px solid red !important;
}

.slick-cell.editable {
  z-index: 11;
  overflow: visible;
  background: white;
  border-color: black;
  border-style: solid;
}

.slick-cell:focus {
  outline: none;
}

.slick-reorder-proxy {
  display: inline-block;
  background: blue;
  opacity: 0.15;
  filter: alpha(opacity = 15);
  cursor: move;
}

.slick-reorder-guide {
  display: inline-block;
  height: 2px;
  background: blue;
  opacity: 0.7;
  filter: alpha(opacity = 70);
}

.slick-selection {
  z-index: 10;
  position: absolute;
  border: 2px dashed black;
}



// *****
// Theme
// *****

$font: 9pt arial, sans-serif;
// $cellHeight: 

$headerBg: #F3F3F3;
$headerBgOnHover: darken($headerBg, 3%);
$headerRowBg: #fafafa;
$mainBg: #fff;
$mainBorderColor: #ccc;
$footerBg: $mainBg;
$activeCellBorder: #5292F7;
$changedCellBg: yellow;
$selectedCellBg: #E2EDFF;
$activeRowBg: transparent;


@mixin focus-border {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}

@mixin small-input {
  height: 30px;
  padding: 5px 5px;
  font-size: 12px;
  line-height: 1.5;
}


// Header
.slick-header {
  font: $font;
  color: black;
  background-color: $headerBg;

  &.ui-state-default {
    border: 1px solid $mainBorderColor;}}

.slick-header-column.ui-state-hover {
  background: $headerBgOnHover;}

.slick-header-menubutton{
  background-color: #F3F3F3;}
.selected-header {
  background-color: #C8C8C8;}

.slick-headerrow {
  background: $headerRowBg;

  &.ui-state-default {
    border: 1px solid $mainBorderColor;}
}

.slick-headerrow-column {
  // background: $headerRowBg;
}

input.column-filter {
  font: $font;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  //border-radius: 0px;

  // @include small-input;

  &.has-value {
    background-color: #fcf8e3;
  }

  &:focus {
    @include focus-border;
  }
}

.slick-headerrow-columns {
  // render cancel button
  input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
  }
}


// Viewport
.slick-viewport {
  background: $mainBg;
  border-right: 1px solid $mainBorderColor;
  border-bottom: 1px solid $mainBorderColor;
  border-left: 1px solid $mainBorderColor;
}

.slick-row.active {
  background-color: $activeRowBg;
}

.slick-cell,
.slick-footerrow > .slick-footerrow-columns > .slick-footerrow-column {
  font: $font;
  border-color: transparent #CCCCCC #CCCCCC transparent;
  border-style: solid solid solid solid;
  border-width: 0px 1px 1px 0px;
  padding: 4px 4px 0px 6px;

  &.active {
    z-index: 5;
    border-color: $activeCellBorder;
    border-style: solid;
    border-width: 2px;
    padding-top: 2px;
    margin-bottom: 1px;
    padding-left: 4px;

    &.align-right {
      padding-right: 3px;
    }
  }

  &.changed {
    background-color: $changedCellBg;}

  &.selected {
    background-color: $selectedCellBg;}

  &.row-header {
    text-align: right;
    padding-right: 7px;
    background: $headerBg;
    color: gray;

    &.active {
      padding-right: 6px;
    }

    &.selected {
      background-color: transparent; /* show default selected row background */
    }
  }
}

// footer
.slick-footerrow {
  font: $font;
  color: black;
  background-color: $footerBg;

  border-top: none;

  > .slick-footerrow-columns {
    > .slick-footerrow-column {
      min-height: 100%;
      height: 100%;
      border-width: 0 0 0 1px;
      border-style: none none none solid;
      text-align: right;
      vertical-align: center;
      font-weight: bold; }}

  &.ui-state-default {
    border: 1px solid $mainBorderColor;
    border-top: none; }}