.form-group {
  .label-align-right {
    @media (min-width: 768px) {
      text-align: right!important;
    }
  }

  &.list-group-item.row {
    position: inherit;
    display: inherit;
    margin-bottom: 0;
  }
}
