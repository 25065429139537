.slick-cell.selected.active.editable {
  padding: 0;
}

.slick-cell.selected.active.editable > select {
  margin: 0;
  border: none;
  width: 100%;
  padding: 0;
  height: 100%;
  background: transparent;
}
