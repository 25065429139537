// @import '~bootstrap/scss/bootstrap.scss';

.itp-form {
  position: relative;
  transition: height 200ms ease;
  // border: 1px dashed blue;
  height: 100%;
}


textarea.monospace {
  font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace;
}

.form-control.form-control-readonly {
  background-color: #e9ecef;
  // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
  opacity: 1;
}  


.custom-select:disabled {
  color: #495057;
  background-color: #e9ecef;
}