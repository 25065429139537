$itp-icon-vertical-offset: -0.15em;

// Animations
$itp-icon-animation-cylon-duration: 0.75s !default;
$itp-icon-animation-cylon-vertical-duration: $itp-icon-animation-cylon-duration !default;
$itp-icon-animation-fade-duration: 0.75s !default;
$itp-icon-animation-spin-duration: 2s !default;
$itp-icon-animation-spin-reverse-duration: $itp-icon-animation-spin-duration !default;
$itp-icon-animation-spin-pulse-duration: 1s !default;
$itp-icon-animation-spin-reverse-pulse-duration: $itp-icon-animation-spin-pulse-duration !default;
$itp-icon-animation-throb-duration: 0.75s !default;


// Base icon styling
.itp-icon {
  &.svg {
    display: inline-block;
    overflow: visible;
    // The following is required to compensate for alignment
    // issues with the Bootstrap Icons alpha implementation
    vertical-align: $itp-icon-vertical-offset;
  }

  &.itp-icon-animation-cylon,
  &.itp-iconstack .itp-icon-animation-cylon > g {
    transform-origin: center;
    animation: $itp-icon-animation-cylon-duration infinite ease-in-out alternate
      itp-icon-animation-cylon;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-cylon-vertical,
  &.itp-iconstack .itp-icon-animation-cylon-vertical > g {
    transform-origin: center;
    animation: $itp-icon-animation-cylon-vertical-duration infinite ease-in-out alternate
      itp-icon-animation-cylon-vertical;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-fade,
  &.itp-iconstack .itp-icon-animation-fade > g {
    transform-origin: center;
    animation: $itp-icon-animation-fade-duration infinite ease-in-out alternate itp-icon-animation-fade;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-spin,
  &.itp-iconstack .itp-icon-animation-spin > g {
    transform-origin: center;
    animation: $itp-icon-animation-spin-duration infinite linear normal itp-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-spin-reverse,
  &.itp-iconstack .itp-icon-animation-spin-reverse > g {
    transform-origin: center;
    animation: $itp-icon-animation-spin-reverse-duration infinite linear reverse itp-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-spin-pulse,
  &.itp-iconstack .itp-icon-animation-spin-pulse > g {
    transform-origin: center;
    animation: $itp-icon-animation-spin-pulse-duration infinite steps(8) normal itp-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-spin-reverse-pulse,
  &.itp-iconstack .itp-icon-animation-spin-reverse-pulse > g {
    transform-origin: center;
    animation: $itp-icon-animation-spin-reverse-pulse-duration infinite steps(8) reverse
      itp-icon-animation-spin;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }

  &.itp-icon-animation-throb,
  &.itp-iconstack .itp-icon-animation-throb > g {
    transform-origin: center;
    animation: $itp-icon-animation-throb-duration infinite ease-in-out alternate
      itp-icon-animation-throb;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
    }
  }
}

// Animation for spinning icons
@keyframes itp-icon-animation-cylon {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes itp-icon-animation-cylon-vertical {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(-25%);
  }
}

@keyframes itp-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes itp-icon-animation-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes itp-icon-animation-throb {
  0% {
    opacity: 0.5;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

// Make icons slightly larger in buttons, nav-links, dropdowns, and input-group-text
.btn,
.nav-link,
.dropdown-toggle,
.dropdown-item,
.input-group-text {
  .itp-icon {
    &.svg {
      font-size: 125%;
      vertical-align: text-bottom;
    }
  }
}


.btn {
  svg.iconify {
    font-size: 1.3rem;
  }
  
  &.btn-md {
    svg.iconify {
      font-size: 1.3rem;
    }
  }
  &.btn-sm {
    svg.iconify {
      font-size: 1.0rem;
    }
  }
}
