.slick-cell {
  .overdue {
    font-weight: bold;

    &.highlight-1 {
      color: blue;
    }

    &.highlight-2 {
      color: #bca136;
    }

    &.highlight-3 {
      color: #c2571a;
    }

    &.highlight-4 {
      color: #c02f1d;
    }
  }
}

.itp-badge {
  display: inline-block;
  padding: 0 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;

  &.yellow { background-color: rgb(251, 202, 4); }

  &.cyan { background-color: rgb(95, 229, 244); }

  &.purple { background-color: rgb(172, 160, 239); }
  &.purple-light { background-color: rgb(212, 197, 249); }

  &.blue-light { background-color: rgb(199, 222, 248); }
  &.blue-dark { background-color: rgb(83, 25, 231); }

  &.invoice-kind-outgoing {
    background-color: #03A9F4;
    color: #fff;
  }

  &.invoice-kind-incoming {
    background-color: #3F51B5;
    color: #fff;
  }

  &.badge-invoice-type-correction {
    // correction
    background-color: rgb(251, 202, 4);
    // color: #fff;
  }

  &.badge-invoice-type-storno {
    // storno
    background-color: #dc3545;
    color: #fff;
  }

  &.badge-invoice-type-proforma {
    // pro forma
    background-color: #C6FF00;
    // color: #fff;
  }
}

.card.card-list {
  border: none;

  & > .card-header {
    padding: 0;
    padding-bottom: 7px;
    border: none;
    background: transparent;

    display: flex;
    align-items: center !important;
  }

  & > .card-body {
    padding: 0;
    border: none;
  }
}

// .hr- {
//   &bottom {
//     position: relative;

//     &:after {
//       content: '';
//       position: absolute;
//       width: 30px;
//       height: 1px;
//       background: black;
//       top: 100%;
//       left: 0;
//     }
//   }
// }


.badge {
  &.badge-meter-reading-type {
    &--automated {
      color: #fff !important;
      background-color: #33b5e5 !important;
    }
    &--manual {
      color: #fff !important;
      background-color: #a6c !important;
    }
  }

  &.badge-contract-type {
    &--rental {
      color: #fff !important;
      background-color: #33b5e5 !important;
    }
    &--vendor {
      color: #fff !important;
      background-color: #a6c !important;
    }
    &--service {
      color: #000 !important;
      background-color: #fadb56 !important;
    }
  }
}


.bg-page {
  background-color: #F8F9FA !important;
}

.uc-border {
  &.modal-header,
  &.card-header {
    border-top-width: 4px;
    border-top-style: solid;
  }

  &.modal-footer {
    border-bottom-width: 4px;
    border-bottom-style: solid;
  }
}

.slick-group-toggle {
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    background-color: currentColor;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
    margin-right: 5px;

  &.collapsed {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12.675 12L10.3 14.375q-.275.275-.275.688t.275.712q.3.3.713.3t.712-.3L14.8 12.7q.3-.3.3-.7t-.3-.7l-3.1-3.1q-.3-.3-.7-.287t-.7.312q-.275.3-.288.7t.288.7zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8'/%3E%3C/svg%3E");
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
  }

  &.expanded {
    --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 12.675L9.625 10.3q-.275-.275-.687-.275t-.713.275q-.3.3-.3.713t.3.712L11.3 14.8q.3.3.7.3t.7-.3l3.1-3.1q.3-.3.287-.7t-.312-.7q-.3-.275-.7-.288t-.7.288zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8'/%3E%3C/svg%3E");
    -webkit-mask-image: var(--svg);
    mask-image: var(--svg);
  }
}


.material-symbols--expand-circle-down-outline-rounded {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M12 12.675L9.625 10.3q-.275-.275-.687-.275t-.713.275q-.3.3-.3.713t.3.712L11.3 14.8q.3.3.7.3t.7-.3l3.1-3.1q.3-.3.287-.7t-.312-.7q-.3-.275-.7-.288t-.7.288zM12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}

.dropdown.b-dropdown.apply-inline-button {
  & > .btn.dropdown-toggle {
    & > div {
      display: inline;
    }
  }
}