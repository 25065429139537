// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

@import './vars';
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';
@import '~splitpanes/dist/splitpanes.css';
@import "~vue-select/src/scss/vue-select.scss";
@import '~vue2-datepicker/index.css';
@import './router-tab';

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import '@fortawesome/fontawesome-free/scss/fontawesome';
@import '@fortawesome/fontawesome-free/scss/solid';
@import '@fortawesome/fontawesome-free/scss/regular';
@import '@fortawesome/fontawesome-free/scss/brands';
@import '@fortawesome/fontawesome-free/scss/v4-shims';

@import '~vue-virtual-scroller/dist/vue-virtual-scroller.css';


// @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css");



@import './icons';


html {
  font-size: smaller;
}

body.application {
  background-color: #e0e3eb;
}

small:focus {
  outline: none;
}

.border-transparent {
  border-color: transparent !important;
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}


.bs-caret {
  &.up {
    border-bottom: 4px solid #000000;
    border-top: none;
  }
  &.left {
    border-top: 4px solid transparent;
    border-right: 5px solid #000000;
    border-bottom: 4px solid transparent;
    border-left: none;
  }
  &.right {
    border-top: 4px solid transparent;
    border-right: none;
    border-bottom: 4px solid transparent;
    border-left: 5px solid #000000;
  }
}


.app {
  user-select: text;
  webkit-user-select: text;
  moz-user-select: text;
  ms-user-select: text;

  &__navbar {
    .navbar {
      // box-shadow: 0 3px 6px 0 rgba(0,0,0,.05);
    }

    .nav-item.dropdown {
      z-index: 1030;
    }

    .navbar-nav {
      border-bottom: 1px solid transparent;
    }

    .navbar-nav {
      .nav-item {
        .active {
          // border-bottom: 1px solid rgba(0, 0, 0, 0.55);
        }
      }

      .nav-item .b-nav-dropdown {
        .nav-item {
          .active {
            //border-bottom: 1px transparent rgba(0, 0, 0, 0.55);
          }
        }
      }
    }
  }

  &__content {
  }

  &__content-container,
  .router-tab,
  .router-tab-page {
    position: relative;
    width: 100%;
    height: 100%;
    // padding: 10px 15px;
    overflow: hidden;
  }
}

.page {
  height: 100% !important;
  overflow: auto !important;

  &--grid {
    padding: 10px 15px;
    padding-top: 1.1rem;
  }

  &__toolbar {
    /* sticky-top */
    position: sticky;
    top: 0;
    z-index: 1020;
    width: 100%;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;

    display: flex;
    align-items: center !important;

    &.slim {
      padding: 0 !important;
    }
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 1020;
    width: 100%;

    display: flex;
    align-items: center !important;
  }

  &__content {
    padding-bottom: 1rem !important;
  }
}

.page--v2 {
  height: 100% !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;

  &.overflow-auto {
    overflow: auto !important;
  }

  &--grid {
    padding: 10px 15px;
    padding-top: 1.1rem;
  }

  &__toolbar {
    /* sticky-top */
    /* position: sticky;
    top: 0;
    */
    z-index: 1020;
    width: 100%;
    background-color: white;
    padding: 15px 0;

    display: flex;
    align-items: center !important;
  }

  &__content {
    padding-bottom: 1rem !important;
    overflow: hidden;
  }
}

.modal-body {
  .page__toolbar {
    &.slim {
      margin: -1rem !important;
    }
  }
}


.height-full {
  height: 100%;
  min-height: 100%;
}


// .application :not(input):not(textarea):not(.selectable),
.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.stretch {
  &-all {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;

    &.position-absolute {
      top:0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}


.box-stretch {
  height: 100%;
  min-height: 100%;
  overflow: hidden !important;
}


.slick-cell {
  .badge {
    user-select: none;
  }
}


.splitpanes.default-theme {
  .splitpanes__pane {
    background-color: #fff;
  }
  .splitpanes__splitter {
    background-color: #fff;
  }
}


.no-focus-border {
  &:focus {
    outline: none;
  }
}


.mx-datepicker {
  width: 100%;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: inherit !important;
  background-image: none;
}

.form-group.w-auto {
  input,
  .mx-datepicker {
    width: auto;
  }
}

.font-size-smaller {
  font-size: 0.9rem;
}


.nav.nav-tabs.material {
  border-bottom: 1px solid #dee2e6 !important;

  & > .tab-start {
  }

  & > .nav-item > .nav-link {
    border: none;

    padding: 1rem;
    font-weight: 600;
    color: #6c757d !important;

    &.active {
      box-shadow: rgb(102, 102, 196) 0px -2px 0px 0px inset;
      font-weight: 600;
      color: #495057 !important;
      // font-size: 1.2rem;
    }
  }
}

a.link-dark {
  color: #212529;
}

small.label {
  text-transform: uppercase;
  color: #6c757d;
}

.heading {
  border: none;
  font-weight: 600;
  color: #6c757d !important;
}


.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;

  &.ng-enter {
    transition: 0.3s linear all;
    opacity: 0;}
  &.ng-enter.ng-enter-active {
    opacity: 1;}

  &.ng-leave {
    transition: 0.3s linear all;
    opacity: 1; }
  &.ng-leave.ng-leave-active {
    opacity: 0; }
}


hr {
  &.dashed {
    border-style: dashed;
  }
}



/* Shadows */
.shadow-top {
    box-shadow: 0 -5px 20px -10px rgba(115,115,115,0.75);
}
.shadow-right {
    box-shadow: 10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom {
    box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75);
}
.shadow-left {
    box-shadow: -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-all {
    box-shadow: 0 0 20px rgba(115,115,115,0.75);
}
.shadow-top-right{
    box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
                10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom{
    box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
                0 10px 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-left{
    box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
                -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom-right{
    box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75),
                10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-left-right{
    box-shadow: -10px 0 20px -5px rgba(115,115,115,0.75),
                10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-bottom-left{
    box-shadow: 0 10px 20px -5px rgba(115,115,115,0.75),
                -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom-right{
    box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
                0 10px 20px -5px rgba(115,115,115,0.75),
                10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-top-bottom-left{
    box-shadow: 0 -10px 20px -5px rgba(115,115,115,0.75),
                0 10px 20px -5px rgba(115,115,115,0.75),
                -10px 0 20px -5px rgba(115,115,115,0.75);
}
.shadow-inset {
    box-shadow: inset 0 0 20px rgba(115,115,115,0.75);
}


.dropdown-menu {
  overflow-y:auto; 
  max-height:90vh
}

.text-decoration-line-through {
  text-decoration: line-through;
}


.alert {
  &.alert-in-form {
    padding: 0.5rem 1rem !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0 !important;
  }
}