
$variant-details-row-bg: #fff;

$border-color: #dee2e6;
$border: 1px solid $border-color;

$header-bg: #f8f9fa;
$variant-light-header-bg: #fff;

$editing-row-bg: #fff9e7;

$row-details-bg: #FEFCF3;


$expanded-row-bg: #fff; //#ddf2ff;
$expanded-row-border: 1px solid #1A73E8 !important;
$expanded-row-border-left: 4px solid #1A73E8 !important;

$editCommandsColumnWidth: 80px;
$maxInitialCellWidth: 30px;


@mixin cellborder {
  border-top: none;
  border-right: $border;
  border-bottom: $border;
  border-left: none;
}

@mixin sticky-colheader {
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  @include cellborder;
}

@mixin sticky-rowheader {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  @include cellborder;
}

@mixin disable-spin-button {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type=number] {
    -moz-appearance:textfield; /* Firefox */
  }
}


.itp-datatable {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
  border: $border;

  &:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0.1em solid rgba(0, 0, 0, 0.1);
  }

  &.variant-details {
    background-color: #F3F5F7;
    border: 1px solid #F3F5F7;
    border-radius: calc(0.25rem - 1px);

    & > .itp-datatable__table-wrapper {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &.datatable-basic {
    border: none;
  }

  &__table-wrapper {
    flex: 1 1 auto;
    overflow: auto;
    min-height: 1px;

    margin: 0;
    padding: 0;

    // margin-right: $editCommandsColumnWidth;
  }

  &__table {
    border-collapse: separate;
    border-spacing: 0;

    th, td {
      padding: 0.3rem;
    }

    &.variant {
      &-thead-light {
        th {
          background-color: #fff !important;
        }
      }

      &-basic {
        thead th {
          background-color: transparent !important;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid $border-color;
        }
        tbody tr {
          background-color: transparent !important;

          td, th {
            border-left: none;
            border-right: none;
            border-bottom: none;
          }

          &.datarow {
            & > th[scope='row'] {
              // RowHeader
            }
          }

          &.row-details > td,th {
            border-bottom: 1px solid $border-color;
          }
        }
      }

      &-details {
        border-collapse: separate;
        // border-spacing: 4px;

        $table-bg: #F3F5F7;

        $row-bg: #fff;
        $footer-totals-row-bg: $table-bg;

        $border-color: #EFEEF1;
        $border-radius: 3px;
        $border: 1px solid $border-color !important;

        .itp-datatable {
          &__spacer > td {
            background-color: transparent !important;
            padding: .25rem;
          }
        }

        & > thead {
          box-shadow: 0 4px 4px -4px rgb(0 0 0 / 15%);
        }

        & > thead > tr > th {
          background-color: $table-bg !important;
          border-left: none !important;
          border-right: none !important;
          border-bottom: 1px solid $border-color;
          padding: 0.5rem 0;
        }

        & tbody > tr {
          & > td, th {
            border: none;
            border-left: none !important;
            border-right: none !important;
            border-bottom: none !important;
            // background-color: $variant-details-row-bg !important;
          }

          &.datarow {
            & > th, td {
              background-color: $row-bg;
            }

            & > th:first-child,
            & > td:first-child {
              // border-left: none !important;
              border-left: $border;
              border-top: $border;
              border-radius: $border-radius 0 0 0;
            }
            & > td { border-top: $border; }
            & > th:last-child,
            & > td:last-child {
              // border-right: none !important;
              border-right: $border;
              border-top: $border;
              border-radius: 0 $border-radius 0 0;
            }
          }

          &.datarow.footer-totals {
            & > th, td {
              background-color: $footer-totals-row-bg;
            }
          }

          &.row-details > td {
            background-color: $row-bg;
            border: $border;
            border-top: none !important;
            border-radius: 0 0 $border-radius $border-radius; // 1
            box-shadow: 0 4px 4px -4px rgb(0 0 0 / 15%);
          }

          &.row-details > td,th {
            padding: 0.1rem;
            // background-color: $variant-details-row-bg !important;
          }

          th[scope='row'] {
            background-color: $variant-details-row-bg !important;
          }
        }
      }
    }

    tr {
      &.no-data-wrapper {
        td {
          text-align: center;
          border-right: none;
          border-bottom: none;
          border-left: none;
        }
      }

      &.editing {
        background-color: $editing-row-bg !important;
        th {
          background-color: $editing-row-bg !important;
        }
      }

      &.row-details {
        // background-color: $row-details-bg;

        & > td:first-child {
          border-right: none;
        }
      }

      // &.expanded {
      //   // background: $expanded-row-bg;

      //   &.datarow {
      //     border-left: $expanded-row-border-left;
      //     border-right: $expanded-row-border;

      //     & > th[scope='row'],
      //     & > td {
      //       border-top: $expanded-row-border;
      //       &:first-child {
      //         border-left: $expanded-row-border-left;
      //       }
      //       &:last-child {
      //         border-right: $expanded-row-border;
      //       }
      //     }
      //   }
      //   &.row-details {
      //     border-left: $expanded-row-border-left;
      //     border-right: $expanded-row-border;

      //     & > th[scope='row'],
      //     & > td {
      //       border-bottom: $expanded-row-border;
      //       &:first-child {
      //         border-left: $expanded-row-border-left;
      //       }
      //       &:last-child {
      //         border-right: $expanded-row-border;
      //       }
      //     }
      //   }
      // }
    }

    /* Fixed Headers */
    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;

      // border: none;
      margin: 0;
      padding: 0;

      background-color: $header-bg !important;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border: 0;

      &.user-column {
        max-width: $maxInitialCellWidth;
      }

      &.sortable {
        cursor: pointer;
      }

      &.row-header {
        position: sticky;
        left: 0;
        z-index: 2;
      }

      &:first-child {
        border-left: $border;
      }

      /* Fixed last column */
      &[scope='row']:last-child {
        right: 0;
        background-color: transparent !important;
      }

      &:not([scope='row']).sticky-l {
        @include sticky-colheader;
        left: 0;
      }

      &:not([scope='row']).sticky-r {
        @include sticky-colheader;
        right: 0;
      }

      /* Fixed row header */
      &[scope='row'] {
        @include sticky-rowheader;
        left: 0;

        &.sticky-r {
          left: none;
          right: 0;
        }
      }
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;

      /* For cells, apply the border to one of each side only
        (right but not left, bottom but not top) */
      @include cellborder;

      &.row-details {
        z-index: 1;
      }
    }

    thead {
      th {
        border-bottom: $border;
      }
    }
  }

  &__table-cell {
    max-width: $maxInitialCellWidth;

    @include disable-spin-button;

    & > .cell-header {}

    .form-control {
      margin: 0;
    }

    &.text-center {
      input[type='text'],
      input[type='number'] {
        text-align: center;
      }
    }

    &.text-right {
      input[type='text'],
      input[type='number'] {
        text-align: right;
      }
    }

    .mx-datepicker {
      input {
        text-align: left !important;
      }
    }

    .invalid-feedback {
      background-color: #dc3545;
      color: #fff;
      font-weight: 400;
      line-height: 1.5;
      padding: 0 .4rem;
      border-radius: .25rem;
    }

    // .itp-select2 {
    //   // ignore clear selected button when using tab navigations
    //   .vs__clear {
    //   }
    // }
  }

  /**
    Extra row for "Create new row..." command.
  */
  &__extra-row {
    text-align: center;
    td {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  &__footer {
    &:last-child {
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
    }

    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  // .column__header {
  //   position: relative;
  //   border-right: 1px solid transparent;
  // }

  .column__resizer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 8px;
    cursor: col-resize;
    user-select: none;
    height: 100%;
    margin: 2px 0;

    &:hover {
      border-right: 3px solid $border-color;
    }
  }

  &__row-edit-commands {
    .default-commands {
      .btn {
        color: lighten(#000, 60%);
        &:hover {
          color: inherit;
        }
      }
    }
  }

  .form-control {
    &.is-invalid {
      background-image: none;
      padding-right: 0.5rem !important;
    }
  }
}
