.modal-dialog {
  // &.modal-full-screen {
  //   width: 95%;
  //   height: 90%;
  //   // margin: 0;
  //   max-width: 95%;

  //   .modal-content {
  //     min-height: 100%;
  //     height: auto;

  //     .modal-body {
  //       position: absolute;
  //       top: 56px;
  //       bottom: 65px;
  //       width: 100%;
  //       overflow: auto;
  //     }

  //     .modal-footer {
  //       position: absolute;
  //       bottom: 0;
  //       width: 100%;
  //     }
  //   }
  // }
  //
  &.modal-full-screen {
    width: 95%;
    height: 90%;
    // margin: 0;
    max-width: 95%;

    .modal-content {
      min-height: 100%;
      height: auto;

      display: flex;
      flex-direction: column;

      .modal-header {
      }

      .modal-body {
        // position: absolute;
        height: 100%;
        width: 100%;
        overflow: auto;
      }

      .modal-footer {
        width: 100%;
      }
    }
  }

  .modal-content {
    .modal-header {
      align-items: center;
    }
    .modal-body {
      padding: 0;
    }
    .modal-footer {
      min-height: 40px;
    }
  }

  &.compact {
    .modal-content {
      .modal-header {
        border-bottom: none;
      }
      .modal-body {
        padding: 0;
      }
    }
  }

  &.confirm-dialog {
    &.severity- {
      &alert > .modal-content > .modal-body {
        border-top: 8px solid rgb(220, 53, 69);
      }
      &warning > .modal-content > .modal-body {
        border-top: 8px solid rgb(255, 193, 7);
      }
      &info > .modal-content > .modal-body {
        border-top: 8px solid rgb(0, 123, 255);
      }
    }

    .modal-footer {
      padding: 0.5rem;
    }
  }
}

// .modal:nth-of-type(even) {
//   z-index: 1052 !important;
// }
// .modal-backdrop:nth-of-type(even) {
//   z-index: 1051 !important;
// }

$modal-stack-size: 5;

@for $i from 1 through $modal-stack-size {
  .modal-in-portal:nth-of-type(#{$i}) {
    & > .modal-backdrop {
      z-index: 1040 + ($i - 1) * 20 !important;
    }

    & > .modal.show {
      z-index: 1050 + ($i - 1) * 20 !important;
    }
  }
}
