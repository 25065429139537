@import '~vue-context/src/sass/vue-context';

@import './styles/index.scss';

/* spinner */
.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  -webkit-font-smoothing: none;
  animation: spin 1000ms infinite linear;}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);}
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);}}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);}
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);}}


.slickg {
  display: flex;
  flex-flow: column nowrap;

  &__rowcount {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  &__column-selector {

    .b-dropdown-form {
      padding-right: 0.25rem;
    }

    &__options {
      height: auto;
      max-height: 50vh;
      overflow: auto;
      padding-right: 1.5rem;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;
    }
  }

  &:not(.auto-height) {
    /*
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    */
    height: 100%;
    min-height: 100%;
    width: 100;
    min-width: 100%;
  }

  & > .slickg-container {
    flex: 1;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    & > .slickgrid {
      height: 100%;}}

  .editable-cell.editor-column {
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }

  .slickg-loading-indicator {
    position: absolute;
    right: 70px;
    bottom: 33px;
    z-index: 1000;

    &.ng-enter {
      transition: 0.3s linear all;
      opacity: 0;}
    &.ng-enter.ng-enter-active {
      opacity: 1;}

    &.ng-leave {
      transition: 0.3s linear all;
      opacity: 1; }
    &.ng-leave.ng-leave-active {
      opacity: 0; }
  }

  h4.title {
    display: inline-block;
    margin: 0;}

  .slickg-downloader{
    z-index: 1;
    opacity: .5;
    position: absolute;
    bottom: 30px;
    right: 25px;

    &:hover {opacity: 1;}}

  &.auto-height .slickg-downloader {bottom: 10px;}

  .slick-row.bg-success,
  .slick-cell.bg-success,
  .slick-cell.bg-success-light, {
    background-color: #dff0d8; }
  .slick-row.bg-warning,
  .slick-cell.bg-warning,
  .slick-cell.bg-warning-light {
    background-color: #fcf8e3; }
  .slick-row.bg-danger,
  .slick-cell.bg-danger,
  .slick-cell.bg-danger-light {
    background-color: #f2dede; }
  .slick-row.bg-info,
  .slick-cell.bg-info,
  .slick-cell.bg-info-light {
    background-color: #d9edf7; }
  .slick-row.bg-read-only,
  .slick-cell.bg-read-only,
  .slick-cell.bg-read-only-light {
    background-color: #f1f1f1; }

  .slick-cell.editable.active {
    padding: 0;
    & > .editor-text {
      padding-left: 3px;
      padding-right: 3px;
      width: 100%;
      height: 100%;
      margin: 0;
      border: none;
    }
  }

  .slick-cell {
    &.align-left { text-align: left; }
    &.align-center { text-align: center; }
    &.align-right { text-align: right; }

    // .badge {
    //   letter-spacing: 1.1pt;
    //   font-size: 9pt;
    //   padding: 0.25em 0.4em;
    //   box-shadow: none;
    //   border-radius: 0.25rem;
    // }

    a {
      // no outline on active links
      outline: none;
    }
  }

  .slick-header-column {
    .slick-column-name > input[type='checkbox']{
      vertical-align: middle;
    }
  }

  .slick-resizable-handle {
    width: 8px;
  }

  input.column-filter.has-value {
    background-color: #fcf8e3;
  }

  /**
   * Context menu styles
   */
  .v-context {
    font: 9pt arial, sans-serif;

    a {
      padding: 0.25rem 1rem;
    }
  }
}
