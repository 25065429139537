.itp-button {
  &.btn-link {
    padding: 0;
    margin: 0 0.5rem;
    color: #495057;
  }

  svg {
    font-size: 1.5rem;
  }

  &.btn-sm {
    svg {
      // font-size: 1.25rem;
    }
  }
}

.btn-default-bordered {
  color: #333;
  background-color: #fff;
  border-color: #ccc;

  &:focus {
    color: #333;
    background-color: #e6e6e6;
    border-color: #8c8c8c;
  }
  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  &:active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.btn-default-borderless {
  color: #333;
  background-color: #fff;
  border: none;

  &:focus {
    color: #333;
    background-color: #e6e6e6;
    border: none;
  }
  &:hover {
    color: #333;
    background-color: #e6e6e6;
    border: none;
  }
  &:active {
    color: #333;
    background-color: #e6e6e6;
    border: none;
  }
}
