.itp-widget-card.card {
  border: none !important;
  background-color: transparent !important;

  & > .card-header {
    background-color: transparent !important;
    border: none;
    padding: 0.5rem 1.5rem;
  }

  & > .card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
}