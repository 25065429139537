.itp-sidebar {
  box-sizing: border-box;
  position: relative;

  -ms-touch-action: none;
  touch-action: none;

  * { box-sizing: border-box; }

  // background-color: #f7f7f7;

  transition: 0.3s max-width ease;
  height: 100%;
  display: flex;
  flex-direction: column;

  .itp-sidebar__content {
    height: 100%;
    overflow: hidden;
  }

  .itp-sidebar__toggle {
    height: 10px;

    background-color: #eee;
    // border: 1px solid #eee;
    // background-color: rgba(0, 0, 0, 0.03);

    display: block;
    align-self: flex-end !important;

    text-align: center;
    font-style: normal;
    font-weight: 900;
    height: 50px;
    cursor: pointer;
    border: none;
    // width: 100%;
    width: 30px;
    // &:after {
    //   content: '\f337';
    //   font-family: 'Font Awesome 5 Free';
    // }
    &_slot:after {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  .itp-sidebar__resize-handle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    width: 10px;

    min-height: 1px;

    background-color: #fff;
    box-sizing: border-box;
    flex-shrink: 0;

    &:before, &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(0, 0, 0, .15);
      transition: background-color 0.3s;
    }

    &:hover:before,
    &:hover:after {
      background-color: rgba(0, 0, 0, .25);
    }
    &:first-child {
      cursor: auto;
    }

    width: 14px;
    border-left: 1px solid #eee;
    margin-left: -1px;
    &:before, &:after {
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
    }
    &:before {margin-left: -2px;}
    &:after {margin-left: 1px;}
  }

  .expand-enter-active,
  .expand-leave-active {
    transition: height 0.3s ease;
    overflow: hidden;
  }
  .expand-enter,
  .expand-leave-to {
    height: 0 !important;
  }

  .slide-animation-enter-active {
    transition: width 0.3s ease;
  }
  .slide-animation-leave-active {
    transition: width 0.3s ease;
  }
  .slide-animation-enter,
  .slide-animation-leave-to {
    width: 0 !important;
  }

  .fade-animation-enter-active {
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  .fade-animation-leave-active {
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  .fade-animation-enter,
  .fade-animation-leave-to {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
