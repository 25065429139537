.itp-notification {
  font-family: "Avenir", Helvetica, Arial, sans-serif;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin: 8px auto;
  border-radius: 5px;

  &--primary {
    background-color: #fff;
    color: #5E6372;
    .itp-notification__closeButton,
    .itp-notification__icon { fill: #5E6372 };
  }

  &--success {
    background: #609a22;
    color: #E4E4E4;
    .itp-notification__closeButton,
    .itp-notification__icon { fill: #E4E4E4 };
  }

  &--error {
    background: #B00002;
    color: #E4E4E4;
    .itp-notification__closeButton,
    .itp-notification__icon { fill: #E4E4E4 };
  }

  &--warning {
    background: #FFDE03;
    color: #2F3C5F;
    .itp-notification__closeButton,
    .itp-notification__icon { fill: #2F3C5F };
  }

  &--dark {
    background: #2F3C5F;
    color: #E4E4E4;
    .itp-notification__closeButton,
    .itp-notification__icon { fill: #E4E4E4 };
  }

  &__container {
    position: relative;
    padding: 16px 24px 16px 16px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  &__message {
    p {
      margin: 0;
      font-size: 14px;
    }
  }

  &__closeButton {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 8px;
  }

  &__icon {
    margin-right: 16px;
  }
}
