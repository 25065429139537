.itp-nav-item {
  display: flex;
  flex-direction: row;

  a {
    white-space: nowrap !important;
    flex-grow: 1;
  }

  .nav-item-icon {
    min-width: 25px;
    text-align: center;
    margin-right: .6em;
    align-self: center !important;
  }

  .nav-item-content {
    // margin-left: .8em;
    margin-right: .8em;
    align-self: center !important;
  }


  &.nav-item > a.nav-link,
  & > a.dropdown-item {
    padding: 0.3rem 0.75rem;
    padding-right: 0.3rem;
    display: flex;
  }

  & > a.dropdown-item {
    &.active,
    &:active {
      background-color: #f3f3f3;
      color: #000;
    }
  }
}